// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

export const getDataFromApi = async (
  params,
  controller,
  endPoint,
  setBlock
) => {
  let response = undefined
  setBlock(true)
  await useJwt.axiosRequest(
    `/api/${controller}/${endPoint}`,
    params,
    "post",
    (res) => {
      response = res
    },
    undefined,
    () => {
      if (setBlock) setBlock(false)
    }
  )
  return response
}

export const fetchReportQuestionnaires = createAsyncThunk(
  "reportQuestionnaires/fetchReportQuestionnaires",
  async (props) => {
    // ** Props
    const { params, controller, endPoint, setBlock } = props
    const response = await getDataFromApi(
      params,
      controller,
      endPoint,
      setBlock
    )

    // Fix bug in last pageIndex and select pageCount
    if (response.data.items.length === 0 && response.data.totalPages > 0) {
      params.pageIndex = response.data.totalPages
      response = await getDataFromApi(params, controller, endPoint, setBlock)
    }

    return {
      params,
      data: response.data.items,
      totalPages: response.data.totalPages
    }
  }
)

export const fetchReportQuestionnairesById = createAsyncThunk(
  "reportQuestionnaires/getById",
  async (props) => {
    // ** Props
    const { params, callback, setBlock } = props
    // ** Add data to server
    await useJwt.axiosRequest(
      `/api/questionnaire/GetById?id=${params}`,
      {},
      "get",
      (response) => {
        if (callback) callback(response)
      },
      undefined,
      () => {
        if (setBlock) setBlock(false)
      }
    )

    return params
  }
)

export const fetchGetReportDASSByEncounterId = createAsyncThunk(
  "reportQuestionnaires/getReportDASSByEncounterId",
  async (props) => {
    // ** Props
    const { params, callback, setBlock } = props

    // ** Add data to server
    await useJwt.axiosRequest(
      `/api/questionnaire/GetReportDASSByEncounterId?id=${params}`,
      {},
      "get",
      (response) => {
        if (callback) callback(response)
      },
      undefined,
      () => {
        if (setBlock) setBlock(false)
      }
    )

    return params
  }
)

export const fetchGetScoreGHQ12ByEncounterId = createAsyncThunk(
  "reportQuestionnaires/getScoreGHQ12ByEncounterId",
  async (props) => {
    // ** Props
    const { params, callback, setBlock } = props

    // ** Add data to server
    await useJwt.axiosRequest(
      `/api/questionnaire/GetScoreGHQ12ByEncounterId?id=${params}`,
      {},
      "get",
      (response) => {
        if (callback) callback(response)
      },
      undefined,
      () => {
        if (setBlock) setBlock(false)
      }
    )

    return params
  }
)

export const reportQuestionnairesSlice = createSlice({
  name: "reportQuestionnaires",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedReportQuestionnaire: null
  },
  reducers: {
    selectReportQuestionnaire: (state, action) => {
      state.selectedReportQuestionnaire = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportQuestionnaires.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const { selectReportQuestionnaire } = reportQuestionnairesSlice.actions

export default reportQuestionnairesSlice.reducer
