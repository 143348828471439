// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

export const uploadUrlImage = createAsyncThunk(
  "Institutionsdirectory/upload",
  async (props) => {
    // ** Props
    const { params, callback } = props
    // ** Add data to server
    await useJwt.axiosRequest(
      `/api/institutionsdirectory/uploadUrlImage`,
      params,
      "post",
      (response) => {
        if (callback) callback(response)
      },
      undefined,
      undefined
    )

    return params
  }
)

export const InstitutionsDirectorySlice = createSlice({
  name: "Institutionsdirectory",
  initialState: {}
})

export default InstitutionsDirectorySlice.reducer
