// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

export const getDataFromApi = async (params, controller, endPoint) => {
  let response = undefined
  // setBlock(true)
  await useJwt.axiosRequest(
      `/api/${controller}/${endPoint}`, { providerId: params }, 'post',
      (res) => { response = res }, undefined, () => { /*if (setBlock) setBlock(false)*/ }
  )
  
  return response
}

export const fetchEvents = createAsyncThunk('availabilityCalendar/fetchEvents', async props => {
  // ** Props
  const { params, controller, endPoint } = props
  const response = await getDataFromApi(params, controller, endPoint, undefined)

  return response.data.map(m => (
    { 
      id: m.providerAvailabilityId,
      title: `${m.clinicalService.map(x => x.label).join(', ')} \n\n`,
      start: m.startDatetime,
      end: m.endDatetime,
      url: '',
      allDay: false,
      extendedProps: {
        calendar: 'Business',
        end: m.endDatetime,
        timeInterval: m.timeInterval,
        attentionChannel: m.attentionChannel,
        clinicalService: m.clinicalService,
        providerId: m.providerId,
        personId: m.personId,
        providerFullName: m.providerFullName
      }
    }
  ))
})

export const addEvent = createAsyncThunk('availabilityCalendar/addEvent', async (props, { dispatch }) => {
  // ** Props
  const { params, controller, endPoint, endPointFetch, setBlock, callback } = props  

  await useJwt.axiosRequest(
    `/api/${controller}/${endPoint}`, params, 'post', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  await dispatch(fetchEvents({ params: Number(params.providerId), controller, endPoint: endPointFetch }))
  return params
})

export const updateEvent = createAsyncThunk('availabilityCalendar/updateEvent', async (props, { dispatch }) => {
  // ** Props
  const { params, controller, endPoint, endPointFetch, setBlock, callback } = props

  await useJwt.axiosRequest(
    `/api/${controller}/${endPoint}`, params, 'put', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  await dispatch(fetchEvents({ params: Number(params.providerId), controller, endPoint: endPointFetch }))
  return params
})

export const removeEvent = createAsyncThunk('availabilityCalendar/removeEvent', async props => {
  // ** Props
  const { params, providerId, controller, endPoint, endPointFetch, setBlock, callback } = props

  await useJwt.axiosRequest(
    `/api/${controller}/${endPoint}?id=${params}`, {}, 'delete', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  await dispatch(fetchEvents({ params: providerId, controller, endPoint: endPointFetch }))
  return params
})

export const availabilityCalendarSlice = createSlice({
  name: 'availabilityCalendar',
  initialState: {
    events: [],
    selectedEvent: {}
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload
      })
  }
})

export const { selectEvent } = availabilityCalendarSlice.actions

export default availabilityCalendarSlice.reducer
