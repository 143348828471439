import axios from 'axios'
import CustomSweetAlert from '../../../views/components/formElements/customSweetAlert'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const roleId = this.getRoleId()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          config.headers.roleid = `${roleId}`
        }
        
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config
        
        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true            
            this.refreshToken()
              .then(r => {
                this.isAlreadyFetchingAccessToken = false

                // ** Update accessToken in localStorage
                this.setToken(JSON.stringify(r.data.token))
                this.setRefreshToken(JSON.stringify(r.data.refreshToken))

                this.onAccessTokenFetched(JSON.stringify(r.data.token))
              })
              .catch((err) => {
                if (err.response.status === 404) {
                  // ** Logout
                  CustomSweetAlert(
                    'Sesión expirada',
                    'La sesión ha expirado.<br/>Por favor, inicia sesión de nuevo.',
                    'warning', 
                    () => {
                      // Clean all local storage
                      localStorage.clear()
                      window.location.href = '/login'
                    },
                    undefined,
                    false,
                    "Aceptar"
                  )
                }
              })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.storageTokenKeyName))
  }

  getUserId() {
    const userData = JSON.parse(localStorage.getItem(this.jwtConfig.storageUserData))
    return userData?.userId ?? undefined
  }

  getPersonId() {
    const userData = JSON.parse(localStorage.getItem(this.jwtConfig.storageUserData))
    return userData?.personId ?? undefined
  }

  getRoleId() {
    const roleData = JSON.parse(localStorage.getItem(this.jwtConfig.storageRoleData))
    return Number(roleData?.roleId) ?? undefined
  }

  getRefreshToken() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName))
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(process.env.REACT_APP_URL_WEP_API + this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(process.env.REACT_APP_URL_WEP_API + this.jwtConfig.registerEndpoint, ...args)
  }

  requestPasswordRecovery(...args) {
    return axios.post(process.env.REACT_APP_URL_WEP_API + this.jwtConfig.passwordRecoveryEndpoint, ...args)
  }

  validateRestoreToken(...args) {
    return axios.post(process.env.REACT_APP_URL_WEP_API + this.jwtConfig.validateRestoreTokenEndpoint, ...args)
  }

  changePasswordByRestoreToken(...args) {
    return axios.post(process.env.REACT_APP_URL_WEP_API + this.jwtConfig.changePasswordByRestoreTokenEndpoint, ...args)
  }

  validation(...args) {
    return axios.post(process.env.REACT_APP_URL_WEP_API + this.jwtConfig.validationEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(`${process.env.REACT_APP_URL_WEP_API}/api/login/refreshtoken`, {
      token: this.getToken(),
      refreshToken: this.getRefreshToken()
    })
  }

  // Emergency line
  getDataEmergencyLine(...args) {
    return axios.get(process.env.REACT_APP_URL_WEP_API + this.jwtConfig.getEmergencyLineEndpoint, ...args)
  }

  updateDataEmergencyLine(...args) {
    return axios.put(process.env.REACT_APP_URL_WEP_API + this.jwtConfig.updateEmergencyLineEndpoint, ...args)
  }

  getPageList(endpoint, ...args) {
    return axios.post(process.env.REACT_APP_URL_WEP_API + endpoint, ...args)
  }

  async axiosRequest(endpoint, params, type = 'post', callbackSuccess, callbackError, callbackFinish) {
    return await axios({
      method: type,
      url: process.env.REACT_APP_URL_WEP_API + endpoint,
      data: params,
      timeout: 30000,
      validateStatus: (status) => {
        // if (status === 401) {          
        //   CustomSweetAlert('Sin acceso', 'No tienes permisos sufientes', 'warning', () => { if (callbackFinish) callbackFinish() })
        // }
        return status >= 200 && status < 300 // default
      }
    })
      .then((res) => {
        if (callbackSuccess) callbackSuccess(res)
      })
      .catch((err) => {
        if (callbackError) callbackError(err)

        const data = err?.response?.data ?? undefined
        const genericMessage = 'Se ha detectado una inconsistencia en la aplicación. Por favor, inténtelo de nuevo más tarde o contacte con el soporte técnico si el problema persiste'
        if (data === undefined) CustomSweetAlert('', genericMessage, 'warning')
        else CustomSweetAlert('', data.message ?? genericMessage, 'warning')
      })
      .finally(() => {
        if (callbackFinish) callbackFinish()
      })
  }
}
