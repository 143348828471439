// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from './authentication'
import institutions from '@src/views/pages/institutionsDirectory/crud/list/getData'
import listDirectory from '@src/views/pages/institutionsDirectory/listDirectory/getData'
import SelfReportedQuestionnairelist from "../views/pages/selfReportedQuestionnaire/list/getData"
import permission from '@src/views/pages/permission/list/getData'
import roleslist from '@src/views/pages/roles/list/getData'
import calendar from '@src/views/pages/teleorientation/AvailabilityManagement/calendar/store'
import attentionManagement from '@src/views/pages/teleorientation/AttentionManagement/list/getData'
import AttentionCalendar from '@src/views/pages/teleorientation/Attention/calendar/store'
import selfReportedQuestionnairesForms from '@src/views/pages/selfReportedQuestionnaires/forms/list/getData'
import encounterType from '@src/views/pages/encounterType/store'
import fieldType from '@src/views/pages/fieldType/store'
import managementForm from '@src/views/pages/managementForm/store'
import forum from "@src/views/pages/forumadmin/store"
import topic from "@src/views/pages/forumTopic/store"
import topicPending from "@src/views/pages/forumTopicPending/store"
import faqCategory from "@src/views/pages/faqCategory/store"
import faq from "@src/views/pages/faq/store"
import faqPending from "@src/views/pages/FAQPending/store"
import informativeCapsuleCategory from "@src/views/pages/informativeCapsuleCategory/store"
import informativeCapsule from "@src/views/pages/informativeCapsule/store"
import users from "@src/views/pages/users/store"
import PatientBoard from "@src/views/pages/patientBoard/store"
import reportQuestionnaires from "@src/views/pages/selfReportedQuestionnaires/report/store"
import topicPerson from "@src/views/pages/forumTopicPerson/storePerson"
import faqPendingPerson from "@src/views/pages/faqPendingPerson/store"
import InstitutionsDirectory from "@src/redux/institutionsDirectory/index"
import clinicalHistory from "@src/views/pages/clinicalHistory/store"
import informativeCapsuleEnable from "@src/views/pages/informativeCapsuleEnable/store"

const rootReducer = { 
    auth, 
    navbar,
    layout,
    institutions,
    listDirectory,
    roleslist,
    permission,
    calendar,
    attentionManagement,
    AttentionCalendar,
    selfReportedQuestionnairesForms,
    SelfReportedQuestionnairelist,
    reportQuestionnaires,
    encounterType,
    fieldType,
    managementForm,
    forum,
    topic,
    topicPending,
    topicPerson,
    faqCategory,
    faq,
    faqPending,
    faqPendingPerson,
    informativeCapsuleCategory,
    informativeCapsule,
    informativeCapsuleEnable,
    users,
    PatientBoard,
    InstitutionsDirectory,
    clinicalHistory
}

export default rootReducer