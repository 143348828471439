// ** Auth Endpoints
export default {
  loginEndpoint: '/api/login/authenticate',
  registerEndpoint: '/api/login/registeruser',
  passwordRecoveryEndpoint: '/api/login/RequestPaswwordRecovery',
  validateRestoreTokenEndpoint: '/api/login/ValidateRestoreToken',
  changePasswordByRestoreTokenEndpoint: '/api/login/ChangePasswordByRestoreToken',
  validationEndpoint: '/api/utils/ValidateExistenceByEntity',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // ** Emergency line end points
  getEmergencyLineEndpoint: '/api/crueemergencyline/GetEmergencyLine',
  updateEmergencyLineEndpoint: '/api/crueemergencyline/update',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  // ** Value of this property will be used as key to store userId in storage
  storageUserData: 'userData',
  storageRoleData: 'role'
}
