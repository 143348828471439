// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

export const getDataFromApi = async (params, controller, endPoint) => {
  let response = undefined

  // setBlock(true)
  await useJwt.axiosRequest(
      `/api/${controller}/${endPoint}`, { personId: params }, 'post',
      (res) => { response = res }, undefined, () => { /*if (setBlock) setBlock(false)*/ }
  )
  
  return response
}

export const fetchEvents = createAsyncThunk('attentionCalendar/fetchEvents', async props => {
  // ** Props
  const { params, controller, endPoint, filter = undefined } = props
  const response = await getDataFromApi(params, controller, endPoint, undefined)

  return response.data
    .filter(f => (filter ? filter.includes(f.attentionChannelType) : true))
    .map(m => ({ 
      id: m.appointmentId,
      title: m.patientFullName,
      start: m.startDatetime,
      end: m.endDatetime,
      url: '',
      allDay: false,
      appointmentStateType: m.appointmentStateType,
      extendedProps: {
        calendar: m.attentionChannelType,
        end: m.endDatetime,
        attentionChannel: { value: m.attentionChannelTypeId, label: m.attentionChannelType },
        clinicalService: { value: m.clinicalServiceTypeId, label: m.clinicalServiceType },
        providerId: m.providerId,
        personId: m.patientId,
        providerFullName: m.providerFullName,
        appointmentStateType: m.appointmentStateType
      }
    }
  ))
})

export const addEvent = createAsyncThunk('attentionCalendar/addEvent', async (props, { dispatch }) => {
  // ** Props
  const { params, controller, endPoint, endPointFetch, setBlock, callback } = props

  await useJwt.axiosRequest(
    `/api/${controller}/${endPoint}`, params, 'post', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  await dispatch(fetchEvents({ params: Number(params.providerId), controller, endPoint: endPointFetch }))
  return params
})

export const updateEvent = createAsyncThunk('attentionCalendar/updateEvent', async (props, { dispatch }) => {
  // ** Props
  const { params, controller, endPoint, endPointFetch, setBlock, callback } = props

  await useJwt.axiosRequest(
    `/api/${controller}/${endPoint}`, params, 'put', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  await dispatch(fetchEvents({ params: Number(params.providerId), controller, endPoint: endPointFetch }))
  return params
})

export const removeEvent = createAsyncThunk('attentionCalendar/removeEvent', async props => {
  // ** Props
  const { params, providerId, controller, endPoint, endPointFetch, setBlock, callback } = props

  await useJwt.axiosRequest(
    `/api/${controller}/${endPoint}?id=${params}`, {}, 'delete', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  await dispatch(fetchEvents({ params: providerId, controller, endPoint: endPointFetch }))
  return params
})

export const updateFilter = createAsyncThunk('appCalendar/updateFilter', async (props, { dispatch, getState }) => {
  // ** Props
  const { filter, params, controller, endPoint } = props
  
  if (getState().AttentionCalendar.selectedCalendars.includes(filter)) {
    await dispatch(fetchEvents({ params, controller, endPoint, filter: getState().AttentionCalendar.selectedCalendars.filter(i => i !== filter) }))
  } else {
    await dispatch(fetchEvents({ params, controller, endPoint, filter: [...getState().AttentionCalendar.selectedCalendars, filter] }))
  }
  return filter
})

export const updateAllFilters = createAsyncThunk('appCalendar/updateAllFilters', async (props, { dispatch }) => {
  // ** Props
  const { value, params, controller, endPoint } = props

  if (value === true) {
    await dispatch(fetchEvents({ params, controller, endPoint, filter: ['Video conferencia', 'Chat', 'Llamada telefónica'] }))
  } else {
    await dispatch(fetchEvents({ params, controller, endPoint, filter: [] }))
  }
  return value
})

export const attentionCalendarSlice = createSlice({
  name: 'attentionCalendar',
  initialState: {
    events: [],
    selectedEvent: {},
    selectedCalendars: ['Video conferencia', 'Chat', 'Llamada telefónica']
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCalendars.includes(action.payload)) {
          state.selectedCalendars.splice(state.selectedCalendars.indexOf(action.payload), 1)
        } else {
          state.selectedCalendars.push(action.payload)
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          selected = ['Video conferencia', 'Chat', 'Llamada telefónica']
        } else {
          selected = []
        }
        state.selectedCalendars = selected
      })
  }
})

export const { selectEvent } = attentionCalendarSlice.actions

export default attentionCalendarSlice.reducer
