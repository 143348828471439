// ** Custom Hooks
import { createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"

export const getDataFromApi = async (params, controller, endPoint = 'GetPageList', setBlock) => {
  let response = undefined

  setBlock(true)
  await useJwt
    .axiosRequest(
      `/api/${controller}/${endPoint}`, params, 'post', 
      (res) => { response = res }, undefined, () => { if (setBlock) setBlock(false) }
    )

  return response
}

export const getDataStore = async parameters => {
  let response = await getDataFromApi(parameters.params, parameters.controller, parameters.endPoint, parameters.setBlock)

  // Fix bug in last pageIndex and select pageCount
  if (response.data.items.length === 0 && response.data.totalPages > 0) {
    parameters.params.pageIndex = response.data.totalPages
    response = await getDataFromApi(parameters.params, parameters.controller, parameters.endPoint, parameters.setBlock)
  }

  return {
    params: parameters.params,
    data: response.data.items,
    totalPages: response.data.totalPages
  }
}

export const appDataSlice = (nameStore, getData) => {
  return createSlice({
    name: nameStore,
    initialState: {
      data: [],
      total: 1,
      params: {},
      allData: [],
      selectedUser: null
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getData.fulfilled, (state, action) => {        
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalPages
        })
    }
  })
}
