// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

export const getDataFromApi = async (params, controller, endPoint, setBlock) => {
  let response = undefined
  setBlock(true)
  await useJwt
    .axiosRequest(
      `/api/${controller}/${endPoint}`,
      params,
      'post',
      (res) => { response = res },
      undefined,
      () => {
        if (setBlock) setBlock(false)
      }
    )
  return response
}

export const fetchInformativeCapsuleById = createAsyncThunk('informativeCapsule/getById', async (props) => {
  // ** Props
  const { params, callback, setBlock } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/informativeCapsule/GetById?id=${params}`, {}, 'get', (response) => { if (callback) callback(response) }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const fetchInformativeCapsule = createAsyncThunk('informativeCapsule/fetchInformativeCapsule', async props => {
  // ** Props
  const { params, controller, endPoint, setBlock, filterCategory = undefined } = props
  const response = await getDataFromApi({ ...params, filterCategory }, controller, endPoint, setBlock)

  // Fix bug in last pageIndex and select pageCount
  if (response.data.items.length === 0 && response.data.totalPages > 0) {
    params.pageIndex = response.data.totalPages
    response = await getDataFromApi({ ...params, filterCategory }, controller, endPoint, setBlock)
  }

  return {
    params,
    data: response.data.items,
    totalPages: response.data.totalPages,
    totalRecords: response.data.totalCount
  }
})

export const fetchGetInformativeCapsuleCategories = createAsyncThunk('informativeCapsule/fetchInformativeCapsuleCategories', async () => {
  let data = []
  await useJwt.axiosRequest('/api/InformativeCapsuleCategory/GetList', { }, 'post', (response) => { data = response.data }, undefined, undefined)
  return data
})

export const updateFilter = createAsyncThunk('informativeCapsule/updateFilter', async (props, { dispatch, getState }) => {
  // ** Props
  const { filter, controller, endPoint, setBlock } = props
  if (getState().informativeCapsule.selectedInformativeCapsuleCategory.includes(filter)) {
    await dispatch(fetchInformativeCapsule({ params: getState().informativeCapsule.params, controller, endPoint, filterCategory: getState().informativeCapsule.selectedInformativeCapsuleCategory.filter(i => i !== filter), setBlock }))
  } else {
    await dispatch(fetchInformativeCapsule({ params: getState().informativeCapsule.params, controller, endPoint, filterCategory: [...getState().informativeCapsule.selectedInformativeCapsuleCategory, filter], setBlock }))
  }
  return filter
})

export const updateAllFilters = createAsyncThunk('informativeCapsule/updateAllFilters', async (props, { dispatch, getState }) => {
  // ** Props
  const { value, controller, endPoint, setBlock } = props

  if (value === true) {
    await dispatch(fetchInformativeCapsule({ params: getState().informativeCapsule.params, controller, endPoint, filterCategory: getState().informativeCapsule.informativeCapsuleCategory.map(i => parseInt(i.informativeCapsuleCategoryId)), setBlock }))
  } else {
    await dispatch(fetchInformativeCapsule({ params: getState().informativeCapsule.params, controller, endPoint, filterCategory: [], setBlock }))
  }
  return value
})

export const addInformativeCapsule = createAsyncThunk('informativeCapsule/add', async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/informativeCapsule/Add`, params, 'post', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const addReaction = createAsyncThunk('informativeCapsule/addReaction', async (props) => {
  // ** Props
  const { params, callbackSuccess } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/informativeCapsule/AddReaction`, 
    params,
    'POST',
    () => { if (callbackSuccess) callbackSuccess() },
    undefined, 
    undefined
  )

  return params
})

export const updateInformativeCapsule = createAsyncThunk('informativeCapsule/update', async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  await useJwt.axiosRequest(
    `/api/informativeCapsule/Update`, params, 'put', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  
  return params
})

export const adduploadInformativeCapsule = createAsyncThunk('informativeCapsule/upload', async (props) => {
  // ** Props
  const { params, callback } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/informativeCapsule/uploadUrlImage`, params, 'post', (response) => { if (callback) callback(response) }, undefined, undefined
  )

  return params
})

export const removeInformativeCapsule = createAsyncThunk('informativeCapsule/remove', async props => {
  // ** Props
  const { params, setBlock, callback } = props
  await useJwt.axiosRequest(
    `/api/informativeCapsule/DeleteLogicallyById?id=${params}`, {}, 'delete', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const informativeCapsuleSlice = createSlice({
  name: 'informativeCapsule',
  initialState: {
    data: [],
    total: 1,
    totalRecords: 0,
    params: {},
    allData: [],
    selectedInformativeCapsule: {},
    selectedInformativeCapsuleCategory: [],
    informativeCapsuleCategory: []
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedInformativeCapsule = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInformativeCapsule.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.totalRecords = action.payload.totalRecords
      })
      .addCase(fetchGetInformativeCapsuleCategories.fulfilled, (state, action) => {
        state.informativeCapsuleCategory = action.payload
        state.selectedInformativeCapsuleCategory = action.payload.map(i => parseInt(i.informativeCapsuleCategoryId))
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedInformativeCapsuleCategory.includes(action.payload)) {
          state.selectedInformativeCapsuleCategory.splice(state.selectedInformativeCapsuleCategory.indexOf(action.payload), 1)
        } else {
          state.selectedInformativeCapsuleCategory.push(action.payload)
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          selected = state.informativeCapsuleCategory.map(i => parseInt(i.informativeCapsuleCategoryId))
        } else {
          selected = []
        }
        state.selectedInformativeCapsuleCategory = selected
      })
  }
})

export const { selectEvent } = informativeCapsuleSlice.actions

export default informativeCapsuleSlice.reducer
