// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

export const getDataFromApi = async (params, controller, endPoint, setBlock) => {
  let response = undefined
  setBlock(true)
  await useJwt
    .axiosRequest(
      `/api/${controller}/${endPoint}`,
      params,
      'post',
      (res) => { response = res },
      undefined,
      () => {
        if (setBlock) setBlock(false)
      }
    )
  return response
}

export const fetchFaqById = createAsyncThunk('faq/getById', async (props) => {
  // ** Props
  const { params, callback, setBlock } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/faq/GetById?id=${params}`, {}, 'get', (response) => { if (callback) callback(response) }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const fetchFaq = createAsyncThunk('faq/fetchFaq', async props => {
  // ** Props
  const { params, controller, endPoint, setBlock, filterCategory = undefined } = props
  const response = await getDataFromApi({ ...params, filterCategory }, controller, endPoint, setBlock)

  // Fix bug in last pageIndex and select pageCount
  if (response.data.items.length === 0 && response.data.totalPages > 0) {
    params.pageIndex = response.data.totalPages
    response = await getDataFromApi({ ...params, filterCategory }, controller, endPoint, setBlock)
  }

  return {
    params,
    data: response.data.items,
    totalPages: response.data.totalPages,
    totalRecords: response.data.totalCount
  }
})

export const fetchGetFaqCategories = createAsyncThunk('faq/fetchFaqCategories', async () => {
  let data = []
  await useJwt.axiosRequest('/api/faqCategory/GetList', { }, 'post', (response) => { data = response.data }, undefined, undefined)
  return data
})

export const updateFilter = createAsyncThunk('faq/updateFilter', async (props, { dispatch, getState }) => {
  // ** Props
  const { filter, controller, endPoint, setBlock } = props
  
  if (getState().faq.selectedFaqCategory.includes(filter)) {
    await dispatch(fetchFaq({ params: getState().faq.params, controller, endPoint, filterCategory: getState().faq.selectedFaqCategory.filter(i => i !== filter), setBlock }))
  } else {
    await dispatch(fetchFaq({ params: getState().faq.params, controller, endPoint, filterCategory: [...getState().faq.selectedFaqCategory, filter], setBlock }))
  }
  return filter
})

export const updateAllFilters = createAsyncThunk('faq/updateAllFilters', async (props, { dispatch, getState }) => {
  // ** Props
  const { value, controller, endPoint, setBlock } = props

  if (value === true) {
    await dispatch(fetchFaq({ params: getState().faq.params, controller, endPoint, filterCategory: getState().faq.faqCategory.map(i => parseInt(i.faqCategoryId)), setBlock }))
  } else {
    await dispatch(fetchFaq({ params: getState().faq.params, controller, endPoint, filterCategory: [], setBlock }))
  }
  return value
})

export const addFaq = createAsyncThunk('faq/add', async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/faq/Add`, params, 'post', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const increaseViewFaq = createAsyncThunk('faq/increaseView', async (props) => {
  // ** Props
  const { params, callback } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/faq/IncreaseViewByFaqId`, params, 'post', () => { if (callback) callback() }
  )

  return params
})

export const updateFaq = createAsyncThunk('faq/update', async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  await useJwt.axiosRequest(
    `/api/faq/Update`, params, 'put', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  
  return params
})

export const removeFaq = createAsyncThunk('faq/remove', async props => {
  // ** Props
  const { params, setBlock } = props
  await useJwt.axiosRequest(
    `/api/faq/DeleteLogicallyById?id=${params}`, {}, 'delete', () =>  undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const faqSlice = createSlice({
  name: 'faq',
  initialState: {
    data: [],
    total: 1,
    totalRecords: 0,
    params: {},
    allData: [],
    faqCategory: [],
    selectedFaq: {},
    selectedFaqCategory: []
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedFaq = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFaq.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.totalRecords = action.payload.totalRecords
      })
      .addCase(fetchGetFaqCategories.fulfilled, (state, action) => {
        state.faqCategory = action.payload
        state.selectedFaqCategory = action.payload.map(i => parseInt(i.faqCategoryId))
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedFaqCategory.includes(action.payload)) {
          state.selectedFaqCategory.splice(state.selectedFaqCategory.indexOf(action.payload), 1)
        } else {
          state.selectedFaqCategory.push(action.payload)
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          selected = state.faqCategory.map(i => parseInt(i.faqCategoryId))
        } else {
          selected = []
        }
        state.selectedFaqCategory = selected
      })
  }
})

export const { selectEvent } = faqSlice.actions

export default faqSlice.reducer
