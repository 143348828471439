// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

export const getDataFromApi = async (
  params,
  controller,
  endPoint,
  setBlock
) => {
  let response = undefined
  setBlock(true)
  await useJwt.axiosRequest(
    `/api/${controller}/${endPoint}`,
    params,
    "post",
    (res) => {
      response = res
    },
    undefined,
    () => {
      if (setBlock) setBlock(false)
    }
  )
  return response
}

export const fetchGetEncounterByPatientList = createAsyncThunk(
  "clinicalHistory/getEncounterByPatientList",
  async (props) => {
    // ** Props
    const { params, callback, setBlock } = props
    // ** Add data to server
    await useJwt.axiosRequest(
      `/api/clinicHistory/GetEncounterByPatientList?patientId=${params}`,
      {},
      "get",
      (response) => {
        if (callback) callback(response)
      },
      undefined,
      () => {
        if (setBlock) setBlock(false)
      }
    )

    return params
  }
)

export const fetchEncounterDetailsById = createAsyncThunk(
  "clinicalHistory/fetchEncounterDetailsById",
  async (props) => {
    // ** Props
    const { params, callback, setBlock } = props
    // ** Add data to server
    await useJwt.axiosRequest(
      `/api/clinicHistory/GetEncounterDetailsById?encounterId=${params}`,
      {},
      "get",
      (response) => {
        if (callback) callback(response)
      },
      undefined,
      () => {
        if (setBlock) setBlock(false)
      }
    )

    return params
  }
)

export const fetchClinicalHistory = createAsyncThunk(
  "clinicalHistory/fetchClinicalHistory",
  async (props) => {
    // ** Props
    const { params, controller, endPoint, setBlock } = props
    const response = await getDataFromApi(
      params,
      controller,
      endPoint,
      setBlock
    )

    // Fix bug in last pageIndex and select pageCount
    if (response.data.items.length === 0 && response.data.totalPages > 0) {
      params.pageIndex = response.data.totalPages
      response = await getDataFromApi(params, controller, endPoint, setBlock)
    }

    return {
      params,
      data: response.data.items,
      totalPages: response.data.totalPages
    }
  }
)

export const clinicalHistorySlice = createSlice({
  name: "clinicalHistory",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedClinicalHistory: {}
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedClinicalHistory = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClinicalHistory.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const { selectEvent } = clinicalHistorySlice.actions

export default clinicalHistorySlice.reducer
