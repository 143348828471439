// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

export const getDataFromApi = async (params, controller, endPoint, setBlock) => {
  let response = undefined
  setBlock(true)
  await useJwt
    .axiosRequest(
      `/api/${controller}/${endPoint}`,
      params,
      'post',
      (res) => { response = res },
      undefined,
      () => {
        if (setBlock) setBlock(false)
      }
    )
  return response
}

export const fetchtopicById = createAsyncThunk('topic/getById', async (props) => {
  // ** Props
  const { params, callback, setBlock } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/topic/GetById?id=${params}`, {}, 'get', (response) => { if (callback) callback(response) }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const fetchTopicPending = createAsyncThunk('topic/fetchTopicPending', async props => {
  // ** Props
  const { params, controller, endPoint, setBlock } = props
  const response = await getDataFromApi(params, controller, endPoint, setBlock)

  // Fix bug in last pageIndex and select pageCount
  if (response.data.items.length === 0 && response.data.totalPages > 0) {
    params.pageIndex = response.data.totalPages
    response = await getDataFromApi(params, controller, endPoint, setBlock)
  }

  return {
    params,
    data: response.data.items,
    totalPages: response.data.totalPages || 1 // Pagination data
  }
})

export const addtopic = createAsyncThunk('topic/add', async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/topic/Add`, params, 'post', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const updatetopic = createAsyncThunk('topic/update', async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  await useJwt.axiosRequest(
    `/api/topic/Update`, params, 'put', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  
  return params
})

export const removetopic = createAsyncThunk('topic/remove', async props => {
  // ** Props
  const { params, setBlock } = props
  await useJwt.axiosRequest(
    `/api/topic/DeleteLogicallyById?id=${params}`, {}, 'delete', () =>  undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const topicSlice = createSlice({
  name: 'topicPending',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedtopic: {}
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedtopic = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTopicPending.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export const { selectEvent } = topicSlice.actions

export default topicSlice.reducer
