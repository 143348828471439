// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

export const getDataFromApi = async (params, controller, endPoint, setBlock) => {
  let response = undefined
  setBlock(true)
  await useJwt
    .axiosRequest(
      `/api/${controller}/${endPoint}`,
      params,
      'post',
      (res) => { response = res },
      undefined,
      () => {
        if (setBlock) setBlock(false)
      }
    )
  return response
}

export const fetchManagementFormById = createAsyncThunk('managementForm/getById', async (props) => {
  // ** Props
  const { params, callback, setBlock } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/form/GetById?id=${params}`, {}, 'get', (response) => { if (callback) callback(response) }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const fetchManagementForm = createAsyncThunk('managementForm/fetchManagementForm', async props => {
  // ** Props
  const { params, controller, endPoint, setBlock } = props
  const response = await getDataFromApi(params, controller, endPoint, setBlock)

  // Fix bug in last pageIndex and select pageCount
  if (response.data.items.length === 0 && response.data.totalPages > 0) {
    params.pageIndex = response.data.totalPages
    response = await getDataFromApi(params, controller, endPoint, setBlock)
  }

  return {
    params,
    data: response.data.items,
    totalPages: response.data.totalPages
  }
})

export const addManagementForm = createAsyncThunk('managementForm/add', async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/form/Add`, params, 'post', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const updateManagementForm = createAsyncThunk('managementForm/update', async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  await useJwt.axiosRequest(
    `/api/form/Update`, params, 'put', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  
  return params
})

export const removeManagementForm = createAsyncThunk('managementForm/remove', async props => {
  // ** Props
  const { params, setBlock, callback } = props
  await useJwt.axiosRequest(
    `/api/form/DeleteLogicallyById?id=${params}`, {}, 'delete', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const managementFormSlice = createSlice({
  name: 'managementForm',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedManagementForm: {}
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedManagementForm = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchManagementForm.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export const { selectEvent } = managementFormSlice.actions

export default managementFormSlice.reducer
