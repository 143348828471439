import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const privileges = []

const userData = JSON.parse(localStorage.getItem('userData'))
const role = JSON.parse(localStorage.getItem('role'))

if (userData !== null && userData.locations !== undefined) {
    // If user has more than one role, show modal to select one
    if (role === null) {
        privileges.push({ action: "read", subject: "HomePage" })
    } else {
        userData
            .locations[0].roles
            .find(f => f.roleId === role.roleId).privileges
            .forEach(element => { 
                privileges.push({ action: element.action, subject: element.resource }) 
            })
    }
}

const existingAbility = userData ? privileges : null

export default new Ability(existingAbility || initialAbility)
