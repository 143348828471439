// ** Third Party Components
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const MySwal = withReactContent(Swal)

const CustomSweetAlert = (Title, Text, Icon, Callback, CallbackCancel = undefined, ShowCancelButton = false, ConfirmButtonText = 'Ok', CancelButtonText = 'Cancelar') => {
  return MySwal.fire({
    title: Title,
    html: Text,
    icon: Icon,
    showCancelButton: ShowCancelButton,
    confirmButtonText: ConfirmButtonText,
    cancelButtonText: CancelButtonText,
    customClass: {
      confirmButton: 'btn btn-primary ms-1',
      cancelButton: 'btn btn-flat-secondary'
    },
    allowOutsideClick: false,
    buttonsStyling: false,
    reverseButtons: true
  }).then(function (result) {
    if (result.value) {
      if (Callback) {
        Callback()
      }
    } else if (result.dismiss === MySwal.DismissReason.cancel) {
      if (CallbackCancel) {
        CallbackCancel()
      }
    }
  })
}

export default CustomSweetAlert
