// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

export const getDataFromApi = async (params, controller, endPoint, setBlock) => {
  let response = undefined
  setBlock(true)
  await useJwt
    .axiosRequest(
      `/api/${controller}/${endPoint}`,
      params,
      'post',
      (res) => { response = res },
      undefined,
      () => {
        if (setBlock) setBlock(false)
      }
    )
  return response
}

export const fetchTopicById = createAsyncThunk('topic/getById', async (props) => {
  // ** Props
  const { params, callback, setBlock } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/topic/GetById?id=${params}`, {}, 'get', (response) => { if (callback) callback(response) }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const fetchTopic = createAsyncThunk('topic/fetchTopic', async props => {
  // ** Props
  const { params, controller, endPoint, setBlock } = props
  const response = await getDataFromApi(params, controller, endPoint, setBlock)

  // Fix bug in last pageIndex and select pageCount
  if (response.data.items.length === 0 && response.data.totalPages > 0) {
    params.pageIndex = response.data.totalPages
    response = await getDataFromApi(params, controller, endPoint, setBlock)
  }

  return {
    params,
    data: response.data.items,
    totalPages: response.data.totalPages
  }
})

export const fetchTopicPending = createAsyncThunk('topic/fetchTopicPending', async props => {
  // ** Props
  const { params, controller, endPoint, setBlock } = props
  const response = await getDataFromApi(params, controller, endPoint, setBlock)

  // Fix bug in last pageIndex and select pageCount
  if (response.data.items.length === 0 && response.data.totalPages > 0) {
    params.pageIndex = response.data.totalPages
    response = await getDataFromApi(params, controller, endPoint, setBlock)
  }

  return {
    params,
    data: response.data.items,
    totalPages: response.data.totalPages
  }
})

export const likeTopic = createAsyncThunk('topic/like', async (props) => {
  // ** Props
  const { params, callback } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/topic/IncreaseLikeByTopicId`, params, 'post', () => { if (callback) callback() }, undefined, undefined
  )

  return params
})

export const loadComments = createAsyncThunk('topic/loadComments', async (props) => {
  // ** Props
  const { params, callback, setBlock } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/topic/GetListTopicMessageByTopicId`, params, 'post', (res) => { if (callback) callback(res) }, undefined, () => { if (setBlock) setBlock(false) }
  )
})

export const addComment = createAsyncThunk('topic/addComment', async (props) => {
  // ** Props
  const { params, callback, setBlock } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/topic/AddTopicMessage`, params, 'post', (res) => { if (callback) callback(res) }, undefined, () => { if (setBlock) setBlock(false) }
  )
})


export const addTopic = createAsyncThunk('topic/add', async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/topic/Add`, params, 'post', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const updateTopic = createAsyncThunk('topic/update', async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  await useJwt.axiosRequest(
    `/api/topic/Update`, params, 'put', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )  
  
  return params
})

export const removeTopic = createAsyncThunk('topic/remove', async props => {
  // ** Props
  const { params, setBlock, callback } = props
  await useJwt.axiosRequest(
    `/api/topic/DeleteLogicallyById?id=${params}`, {}, 'delete', () => { if (callback) callback() }, undefined, () => { if (setBlock) setBlock(false) }
  )

  return params
})

export const topicSlice = createSlice({
  name: 'topic',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTopic: {}
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedTopic = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTopic.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(fetchTopicPending.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export const { selectEvent } = topicSlice.actions

export default topicSlice.reducer
