// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import useJwt from "@src/auth/jwt/useJwt"

// ** Third Party Components
import moment from "moment"
import "moment/locale/es"
moment().locale('es')

export const getDataFromApi = async (params, controller, endPoint, setBlock) => {
  let response = undefined
  setBlock(true)
  await useJwt.axiosRequest(
    `/api/${controller}/${endPoint}`,
    params,
    "post",
    (res) => {
      response = res
    },
    undefined,
    () => {
      if (setBlock) setBlock(false)
    }
  )
  return response
}

export const fetchPatientBoardById = createAsyncThunk("patientBoard/getById", async (props) => {
  // ** Props
  const { params, callback, setBlock } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/PatientBoard/GetById?id=${params}`,
    {},
    "get",
    (response) => {
      if (callback) callback(response)
    },
    undefined,
    () => {
      if (setBlock) setBlock(false)
    }
  )

  return params
})

export const fetchPatientBoard = createAsyncThunk("patientBoard/fetchPatientBoard", async (props) => {
  // ** Props
  const { params, controller, endPoint, setBlock } = props
  const response = await getDataFromApi(params, controller, endPoint, setBlock)

  // Fix bug in last pageIndex and select pageCount
  if (response.data.items.length === 0 && response.data.totalPages > 0) {
    params.pageIndex = response.data.totalPages
    response = await getDataFromApi(params, controller, endPoint, setBlock)
  }

  return {
    params,
    data: response.data.items,
    totalPages: response.data.totalPages
  }
})

export const addPatientBoard = createAsyncThunk("patientBoard/add", async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/PatientBoard/Add`,
    params,
    "post",
    () => {
      if (callback) callback()
    },
    undefined,
    () => {
      if (setBlock) setBlock(false)
    }
  )

  return params
})

export const updatePatientBoard = createAsyncThunk("patientBoard/update", async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  await useJwt.axiosRequest(
    `/api/PatientBoard/Update`,
    params,
    "put",
    () => {
      if (callback) callback()
    },
    undefined,
    () => {
      if (setBlock) setBlock(false)
    }
  )

  return params
})

export const removePatientBoard = createAsyncThunk("patientBoard/remove", async (props) => {
  // ** Props
  const { params, setBlock, callback } = props
  await useJwt.axiosRequest(
    `/api/PatientBoard/DeleteLogicallyById?id=${params}`,
    {},
    "delete",
    () => {
      if (callback) callback()
    },
    undefined,
    () => {
      if (setBlock) setBlock(false)
    }
  )

  return params
})

export const fetchPatientIndicators = createAsyncThunk("patientBoard/getPatientIndicators", async (props) => {
  // ** Props
  const { setBlock } = props

  // ** Add data to server
  let response = undefined

  // ** Add data to server
  await useJwt.axiosRequest(
    `/api/PatientBoard/GetPatientIndicators`,
    {},
    "POST",
    (res) => {
      response = res.data
    },
    undefined,
    () => {
      if (setBlock) setBlock(false)
    }
  )

  return response
})

export const fetchGetDashboardPatient = createAsyncThunk("patientBoard/getDashboardPatient", async (props) => {
  // ** Props
  const { setBlock } = props

  // ** Variables
  let response = undefined

  // ** Add data to server
  await useJwt.axiosRequest(
    '/api/PatientBoard/GetDashboardPatient',
    {},
    "get",
    (res) => {
      response = res.data
    },
    undefined,
    () => {
      if (setBlock) setBlock(false)
    }
  )

  return response
})

export const patientBoardSlice = createSlice({
  name: "patientBoard",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    patientIndicators: {},
    selectedPatientBoard: {},
    dashboardPatientDetails: undefined
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedPatientBoard = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatientBoard.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(fetchPatientIndicators.fulfilled, (state, action) => {
        state.patientIndicators = action.payload
      })
      .addCase(fetchGetDashboardPatient.fulfilled, (state, action) => {
        // ** Accumulated Attention
        const accumulatedAttention = action.payload.reduce((acc, { patientId, amountTeleorientation }) => {
          acc[patientId] = amountTeleorientation
          return acc
        }, {})

        // ** Amount Patient Gender and Age
        const amountPatientGendersAndAge = action.payload.reduce((acc, { patientId, genderId, birthdate }) => {
          acc[patientId] = { genderId, age: moment().diff(birthdate, "years") }
          return acc
        }, {})

        // ** Amount Patient Departments And Cities
        const amountPatientDepartmentsAndCities = action.payload.reduce((acc, { patientId, stateProvinceId, stateProvinceName, cityId, cityName }) => {
          acc[patientId] = { cityId, cityName, stateProvinceId, stateProvinceName }
          return acc
        }, {})

        // ** Get Diagnoses By Quantity of Patients
        const amountPatientDiagnosis = action.payload.reduce((acc, { referenceId, reference, code, source }) => {
          // Quantity of patients per diagnosis
          if (referenceId) {
            acc[referenceId] = { reference: `${code}: ${reference}`, source, amount: acc[referenceId] ? acc[referenceId].amount + 1 : 1 }
          }
          return acc
        }, {})

        state.dashboardPatientDetails = {
          accumulatedAttention: Object.values(accumulatedAttention).reduce((acc, curr) => acc + curr, 0),
          patientGendersAndAge: Object.values(amountPatientGendersAndAge),
          patientStateProvincesAndCities: Object.values(amountPatientDepartmentsAndCities),
          diagnosis: Object.values(amountPatientDiagnosis)
        }
      })
  }
})

export const { selectEvent } = patientBoardSlice.actions

export default patientBoardSlice.reducer
